import React from "react";
// import PropTypes from 'prop-types'

const GameCard = (props) => {
  const finalRating = (props.el.rating.number / 10).toFixed(1);
  // console.log('final rating - ', finalRating)

  const allPlatforms = props.el.platforms.map((platform, j) => {
    let platformName = "";
    switch (platform.name) {
      case "kongregate":
        platformName = "Kongregate";
        break;
      case "armor-games":
        platformName = "Armor Games";
        break;
      default:
        platformName = "___ERROR_2___";
    }
    return (
      <a
        href={platform.url}
        target="_blank"
        rel="noopener noreferrer"
        className="platform button is-link"
        key={j}
      >
        {platformName}
      </a>
    );
  });

  return (
    <div className="game-card-cont">
      <div className="game-card">
        {/* <progress class="progress is-link is-small" value={el.rating.number} max="100">{el.rating.number}</progress> */}
        <h3 className="game-card-title">{props.el.title}</h3>
        {/* <div className="score-tag has-background-white has-text-info"> */}
        <div className="score-tag">
          <div className="score-text">Rating</div>
          <div>{finalRating}</div>
        </div>
        <div className="pie-wrapper progress-95 style-2">
          {/* <span class="label">95<span class="smaller">%</span></span> */}
          <div className="pie">
            <div className="left-side half-circle"></div>
            <div className="right-side half-circle"></div>
          </div>
          <div className="shadow"></div>
        </div>
        <div>
          {props.el.tags !== undefined
            ? props.el.tags.map((tag, j) => (
                <span className="game-tag tag is-info is-light" key={j}>
                  {tag}
                </span>
              ))
            : ""}
        </div>
        <div className="grower"></div>
        <span className="play-on-text">Play</span>
        <div>{allPlatforms}</div>
      </div>
    </div>
  );
};

// TagsList.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       plan: PropTypes.string,
//       price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       description: PropTypes.string,
//       items: PropTypes.array,
//     })
//   ),
// }

export default GameCard;
