import React from "react";
// import PropTypes from 'prop-types'

// dobijem ove tagove iz Mergera
const allTags = [
  { name: "Tower Defense", score: 94 },
  { name: "Strategy", score: 72 },
  { name: "Defense", score: 58 },
  { name: "Upgrades", score: 22 },
  { name: "Upgrade", score: 11 },
  { name: "Incremental", score: 10 },
  { name: "Games of 2015", score: 9 },
  { name: "Mouse Only", score: 8 },
  { name: "Idle", score: 8 },
  { name: "Games of 2014", score: 7 },
];

const TagsList = ({ data }) => (
  <div className="all-tags-box content max-width-1400">
    <h3>Popular Tags:</h3>
    {allTags.map((el, i) => (
      <button
        className="tag-btn button is-link is-outlined is-rounded"
        style={{ margin: "4px" }}
        key={i}
      >
        {el.name}
        <span className="tag-number">{el.score}</span>
        {/* <span className="tag-number">{el.score}</span> */}
      </button>
    ))}
  </div>
);

// TagsList.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       plan: PropTypes.string,
//       price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       description: PropTypes.string,
//       items: PropTypes.array,
//     })
//   ),
// }

export default TagsList;
