import React from "react";
import PropTypes from "prop-types";
import {
  // Link,
  graphql,
} from "gatsby";

import Layout from "../components/Layout";
// import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'

// my stuff
import TagsList from "../components/TagsList";
import GameCard from "../components/GameCard";
import Merger_v2 from "../dataJSON/Merger_v2";

// console.log(Merger_v2);

// const createGameCard = (el, i) => {
//   const finalRating = (el.rating.number / 10).toFixed(1);
//   const allPlatforms = el.platforms.map((platform, j) => {
//     let platformName = "";
//     switch (platform.name) {
//       case "kongregate":
//         platformName = "Kongregate";
//         break;
//       case "armor-games":
//         platformName = "Armor Games";
//         break;
//       default:
//         platformName = "___ERROR_2___";
//     }
//     return (
//       <a
//         href={platform.url}
//         target="_blank"
//         rel="noopener noreferrer"
//         className="platform button is-link"
//         key={j}
//       >
//         {platformName}
//       </a>
//     );
//   });

//   return (
//     <div className="game-card-cont" key={i}>
//       <div className="game-card">
//         {/* <progress class="progress is-link is-small" value={el.rating.number} max="100">{el.rating.number}</progress> */}
//         <h3 className="game-card-title">{el.title}</h3>

//         {/* <div className="score-tag has-background-white has-text-info"> */}
//         <div className="score-tag">
//           <div className="score-text">Rating</div>
//           <div>{finalRating}</div>
//         </div>

//         <div className="pie-wrapper progress-95 style-2">
//           {/* <span class="label">95<span class="smaller">%</span></span> */}
//           <div className="pie">
//             <div className="left-side half-circle"></div>
//             <div className="right-side half-circle"></div>
//           </div>
//           <div className="shadow"></div>
//         </div>

//         <div>
//           {el.tags !== undefined
//             ? el.tags.map((el, j) => (
//                 <span className="game-tag tag is-info is-light" key={j}>
//                   {el}
//                 </span>
//               ))
//             : ""}
//         </div>
//         <div className="grower"></div>
//         <span className="play-on-text">Play</span>

//         <div>{allPlatforms}</div>
//       </div>
//     </div>
//   );
// };

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    {/* <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    ></div> */}

    <div className="">
      <div className="section homepage">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <TagsList />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="content">
              <div id="all-game-cards" className="max-width-1400">
                {Merger_v2[0].map((el, i) => {
                  return <GameCard el={el} key={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  /* <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content"> */

  /* <div className="content">
                        <div className="tile">
                          <h1 className="title">{mainpitch.title}</h1>
                        </div>
                        <div className="tile">
                          <h3 className="subtitle">{mainpitch.description}</h3>
                        </div>
                      </div>
    
                      <div className="columns">
                        <div className="column is-12">
                          <h3 className="has-text-weight-semibold is-size-2">
                            {heading}
                          </h3>
                          <p>{description}</p>
                        </div>
                      </div> */

  /* BLOG section  */
  /* 
                      <div className="column is-12">
                        <h3 className="has-text-weight-semibold is-size-2">
                          Latest stories
                      </h3>
                        <BlogRoll />
                        <div className="column is-12 has-text-centered">
                          <Link className="btn" to="/blog">
                            Read more
                        </Link>
                        </div>
                      </div> */

  /* FEATURES section  */
  /* <Features gridItems={intro.blurbs} />
                      <div className="columns">
                        <div className="column is-12 has-text-centered">
                          <Link className="btn" to="/products">
                            See all products
                        </Link>
                        </div>
                      </div> */
  /* </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */
  // </div >
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
